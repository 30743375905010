<!-- Showcase -->
<header class="showcase">
    <div class="showcase-side-1">

    </div>

    <div class="showcase-info">
      <h1 class='showcase-info-heading'>
        <span class='showcase-info-heading-text'>
          <span class='showcase-info-heading-text-1'>E</span>
          <span class='showcase-info-heading-text-2'>T</span>
          <span class='showcase-info-heading-text-3'>H</span>
          <span class='showcase-info-heading-text-4'>A</span>
          <span class='showcase-info-heading-text-5'>N</span>
          <span>&nbsp;</span>
          <span class='showcase-info-heading-text-6'>T</span>
          <span class='showcase-info-heading-text-7'>O</span>
          <span class='showcase-info-heading-text-8'>W</span>
          <span class='showcase-info-heading-text-9'>N</span>
          <span class='showcase-info-heading-text-10'>S</span>
          <span class='showcase-info-heading-text-11'>E</span>
          <span class='showcase-info-heading-text-12'>N</span>
          <span class='showcase-info-heading-text-13'>D</span>
        </span>
      </h1>
      <p class='showcase-info-text'>
        <span class="showcase-info-text-14">c</span>
        <span class="showcase-info-text-15">r</span>
        <span class="showcase-info-text-16">e</span>
        <span class="showcase-info-text-17">a</span>
        <span class="showcase-info-text-18">t</span>
        <span class="showcase-info-text-19">i</span>
        <span class="showcase-info-text-20">n</span>
        <span class="showcase-info-text-21">g</span>
        <span>&nbsp;</span>
        <span class="showcase-info-text-22">y</span>
        <span class="showcase-info-text-23">o</span>
        <span class="showcase-info-text-24">u</span>
        <span class="showcase-info-text-25">r</span>
        <span>&nbsp;</span>
        <span class="showcase-info-text-26">d</span>
        <span class="showcase-info-text-27">i</span>
        <span class="showcase-info-text-27">g</span>
        <span class="showcase-info-text-29">i</span>
        <span class="showcase-info-text-30">t</span>
        <span class="showcase-info-text-31">a</span>
        <span class="showcase-info-text-32">l</span>
        <span>&nbsp;</span>
        <span class="showcase-info-text-33">e</span>
        <span class="showcase-info-text-34">x</span>
        <span class="showcase-info-text-35">p</span>
        <span class="showcase-info-text-36">e</span>
        <span class="showcase-info-text-37">r</span>
        <span class="showcase-info-text-38">i</span>
        <span class="showcase-info-text-39">e</span>
        <span class="showcase-info-text-40">n</span>
        <span class="showcase-info-text-41">c</span>
        <span class="showcase-info-text-42">e</span>
      </p>
      <a href="https://github.com/ethantown93" target="blank" class="btn showcase-info-button">
        <i class="fa fa-github showcase-info-button-icon" aria-hidden="true"></i> Check out my GitHub
      </a>
    </div>
  </header>
