<section class="contact">
    <form #contactForm='ngForm' (ngSubmit)='onSubmit(contactForm.value); contactForm.reset()' class="contact-form">
        <h1 class='contact-form-h1'>Send Me a Message:</h1>
        <div class="contact-form-field">
            <input type="text" id='full_name' placeholder='Full Name' class="contact-form-field-input" name='full_name' ngModel required #nameRef='ngModel'>
            <label for="full_name" class='contact-form-field-label'>Full Name</label>
            <div class="contact-form-error" *ngIf='!nameRef.valid && nameRef.touched'>
                *Please enter your full name.
            </div>
        </div>
        <div class="contact-form-field">

            <input type="email" id='email' class="contact-form-field-input" name='email' ngModel required email #emailref='ngModel' placeholder='Email'>
            <label for="email" class='contact-form-field-label'>Email</label>
            <div class="contact-form-error" *ngIf='!emailref.valid && emailref.touched'>
                *Please enter a valid email addres.
            </div>
        </div>
        <div class="contact-form-field">
            <textarea #messageRef='ngModel' class="contact-form-field-input" type="text" id='message' class="contact-form-field-input" name='message' ngModel required minLength='20' placeholder='Message'></textarea>
            <label for="message" class='contact-form-field-label'>Message</label>
            <div class="contact-form-error" *ngIf='!messageRef.valid && messageRef.touched'>
                *Please leave a message of at least 20 characters.
            </div>
        </div>
        <button [disabled]="!contactForm.valid" class="contact-form-button" type='submit'>Submit</button>
    </form>
    <button class='contact-bck-home' routerLink='/'> &#8592; Back to Home</button>
</section>


