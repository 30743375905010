<footer class="footer">
    <div class="footer-logo-box">
        <img class='footer-logo' src="./assets/images/logo.svg" alt="logo">
    </div>
    <div class="footer-div">
        <div class="footer-navigation">
            <ul class="footer-list">
                <li class="footer-item"><a href="https://github.com/ethantown93" class="footer-link"><i
                    class="fa fa-github-square fa-2x" aria-hidden="true"></i></a></li>
                <li class="footer-item"><a href="https://www.linkedin.com/in/ethan-townsend-15320a10b/"
                    class="footer-link"><i class="fa fa-linkedin-square fa-2x" aria-hidden="true"></i></a>
                </li>
            </ul>
            <p class='footer-text'> &copy;Copyright Ethan Townsend 2020 - Code & Design by Ethan Townsend</p>
        </div>
    </div>
</footer>