  <!-- Schedule interview -->
  <section class="resume dark">
    <div class="resume-bg-video">
      <video src='./assets/videos/galaxy.mp4' class="resume-bg-video-content" playsinline autoplay muted loop>
      </video>
    </div>
    <div class="resume-content">
      <h2 class='resume-h2'>Want to know more about me?</h2>
      <p class='resume-text'>Click the button below to schedule a time for us to talk! I can't wait to hear from you.
      </p>
      <a href="https://calendly.com/eetfitness/interview" target='blank' class="btn resume-button">
        Let's talk! <i class="fa fa-chevron-right"></i>
      </a>
    </div>
  </section>